import React from 'react';
import { MessageBox } from 'worldapp-ui/shared';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppState } from '../../redux/AppState.types';
import { getPortalId } from '../../redux/login/loginSelector';
import { mapCreatorsToDispatchProps } from '../../utils/redux.utils';
import { loginCreator } from '../../redux/login/login';
import { getLastPortalId, setLastPortalId } from '../../utils/storage.utils';
import { initialLoaderCreator } from '../../redux/initialLoader/initialLoader';
import { LoginStatus } from '../../redux/login/login.types';

/* istanbul ignore next */
const mapStateToProps = (state: AppState) => {
    return {
        portalId: getPortalId(state),
    };
};

const mapDispatchToProps = mapCreatorsToDispatchProps({
    logout: loginCreator.logout,
    initializePreviousPortal: initialLoaderCreator.initialize,
    changeLoginStatus: loginCreator.changeLoginStatus,
});

export type LoginToDifferentPortalProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const LoginToDifferentPortalComponent = ({
    portalId,
    logout,
    initializePreviousPortal,
    changeLoginStatus,
}: LoginToDifferentPortalProps): JSX.Element => {
    const { t } = useTranslation();

    const handleConfirm = () => {
        setLastPortalId(portalId);
        logout();
    };

    const handleCancel = () => {
        changeLoginStatus(LoginStatus.Loading);
        initializePreviousPortal();
    };

    return (
        <MessageBox
            title={t('Login.DifferentPortal.Title')}
            open={true}
            confirmButton={{
                label: t('Login.DifferentPortal.ConfirmButton'),
                clickHandler: handleConfirm,
            }}
            cancelButton={{
                label: t('Login.DifferentPortal.CancelButton'),
                clickHandler: handleCancel,
            }}
        >
            {t('Login.DifferentPortal.Message', {
                newPortal: portalId,
                oldPortal: getLastPortalId(),
            })}
        </MessageBox>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginToDifferentPortalComponent);
