import ReactGA from 'react-ga4';
import { RequestLogEntry } from 'worldapp-fe-utils/build/Http/Http';
import { TasksView } from './redux/menu/menu.types';
import { ALL_TASKS_ID } from './constants/navigation.constants';
import { Task, TasksContentTab } from './redux/content/content.types';

let userId: number | null = null;

let appLoadedTime: number | null = null;
const tagAppLoaded = () => {
    ReactGA.gtag('event', 'performance_timing', {
        app_name: 'Online portal',
        user_id: userId,
        timing_page_load_msec: appLoadedTime,
    });
};

export const onAppLoaded = (): void => {
    if (!appLoadedTime) {
        appLoadedTime = performance.now();
    }
};

export const setAnalitycsUserId = (id: number): void => {
    userId = id;
    tagAppLoaded();
};

export const onContentLoadStart = (
    contentName: string,
    itemId: string,
): {
    onLoadEnd: () => void;
    onLoadError: () => void;
} => {
    const startTime = performance.now();
    return {
        onLoadEnd: () => {
            const loadTime = performance.now() - startTime;
            ReactGA.gtag('event', 'performance_timing', {
                app_name: 'Online portal',
                user_id: userId,
                content: contentName,
                id: itemId,
                timing_content_load_msec: loadTime,
            });
        },
        onLoadError: () => {
            const loadTime = performance.now() - startTime;
            ReactGA.gtag('event', 'performance_timing', {
                app_name: 'Online portal',
                user_id: userId,
                content: contentName,
                id: itemId,
                timing_content_load_error_msec: loadTime,
            });
        },
    };
};

export const onTasksViewSwitch = (view: TasksView, manager: boolean, tdId: number | null): void => {
    ReactGA.gtag('event', 'tasks_view_switch', {
        app_name: 'Online portal',
        mode: manager ? 'team' : 'my',
        task_definition: tdId === ALL_TASKS_ID ? 'all' : tdId,
        target: view,
    });
};

export const onShowHideEmptyTD = (show: boolean): void => {
    ReactGA.gtag('event', 'show_hide_empty_td', {
        app_name: 'Online portal',
        show,
        hide: !show,
    });
};

export const onTaskDetailView = (tab: TasksContentTab, task?: Task): void => {
    if (!task) return;
    ReactGA.gtag('event', 'task_details', {
        app_name: 'Online portal',
        view: tab === TasksContentTab.Details ? 'details' : 'history',
        task_definition: task.taskDefinitionId,
        task_id: task.id,
        task_name: task.summary,
    });
};

export const onTaskDetailAction = (
    action: 'export' | 'due date' | 'status' | 'reassign' | 'assign to me' | 'unassign',
    task?: Task,
): void => {
    if (!task) return;
    ReactGA.gtag('event', 'task_details_action', {
        app_name: 'Online portal',
        action_type: action,
        task_definition: task.taskDefinitionId,
        task_id: task.id,
        task_name: task.summary,
    });
};

// TODO: add excludes and/or regexps if more specific filtering needed
const PERF_LOG_APIS = [
    '/gw/v3/forms',
    '/gw/v3/tasks/filter/assignee',
    '/gw/v3/task-definitions',
    '/gw/v3/task-definitions/meta',
    'gw/v3/forms',
    '/gw/v3/task-properties',
    '/gw/v3/responses',
    '/gw/v3/contact-filters/',
    '/gw/v3/tasks/list',
    '/gw/v3/contact-manager/views/',
    '/gw/v3/tasks/',
    '/gw/v3/tasks',
];

export const logApiPerformance = (rLog: RequestLogEntry): void => {
    if (PERF_LOG_APIS.find(apiToLog => rLog.url.includes(apiToLog))) {
        ReactGA.gtag('event', 'performance_api', {
            app_name: 'Online portal',
            user_id: userId,
            api_path: rLog.url,
            api_response_msec: rLog.time,
        });
    }
};
