import { extractTextFromHtml } from 'worldapp-fe-utils';
import { DEFAULT_TDS_LIMIT } from '../../constants/main.constants';
import { groupBy } from '../../utils/groupBy.utils';

import {
    FETCH_TDS,
    FETCH_META,
    TasksAction,
    TasksDefinitionsState,
    TaskDefinition,
    GET_CREATE_TASK_PERMIT,
    TASK_PERMITS_LOADING,
    FETCH_META_COUNT,
} from './taskDefinitions.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionPromise } from '../index';
import { getTaskDefinitionsIds } from './taskDefinitionsSelector.reselect';
import { ALL_TASKS_ID } from '../../constants/navigation.constants';
import { ApiGW } from '../../types/DTO/api-gw';

const CREATE_TASK_PERMIT = 'create-task';

export const initialState: TasksDefinitionsState = {
    taskDefinitions: [],
    taskDefinitionsById: {},
    totalTDs: 0,
    totalTasks: 0, // TODO: where is it used?
    tdIdsWithCreateTask: [],
    taskPermitsLoading: false,
};

export default function reducer(state = initialState, action: TasksAction): TasksDefinitionsState {
    switch (action.type) {
        case FETCH_TDS: {
            const taskDefinitions = action.taskDefinitions.map(td => ({
                ...td,
                title: extractTextFromHtml(td.title),
                statusesById: groupBy(td.statuses, 'id'),
            }));
            return {
                ...state,
                taskDefinitions,
                taskDefinitionsById: groupBy(taskDefinitions, 'id'),
                totalTDs: action.totalTDs,
            };
        }
        case FETCH_META:
        case FETCH_META_COUNT: {
            const taskDefinitions = action.taskDefinitions.map(td => ({
                ...td,
                statusesById: groupBy(td.statuses, 'id'),
            }));
            const totalTasks =
                action.type === FETCH_META_COUNT ? action.totalTasks : state.totalTasks;
            return {
                ...state,
                taskDefinitions,
                taskDefinitionsById: groupBy(taskDefinitions, 'id'),
                totalTasks,
            };
        }

        case GET_CREATE_TASK_PERMIT: {
            return {
                ...state,
                tdIdsWithCreateTask: action.tdIdsWithCreateTask,
            };
        }

        case TASK_PERMITS_LOADING: {
            return {
                ...state,
                taskPermitsLoading: action.isLoading,
            };
        }

        default:
            return state;
    }
}

export const taskDefinitionsCreator = {
    fetchTaskDefinitions: (): ThunkActionPromise => {
        return (dispatch, getState, { api }) => {
            return api
                .getTaskDefinitions(DEFAULT_TDS_LIMIT, 0)
                .then(response => {
                    dispatch({
                        type: FETCH_TDS,
                        taskDefinitions: response.taskDefinitions,
                        totalTDs: response.total,
                    });
                })
                .then(() => dispatch(taskDefinitionsCreator.fetchTDSMeta()))
                .catch(err => err);
        };
    },

    fetchTDSMeta: (): ThunkActionPromise => {
        return (dispatch, getState, { api }) => {
            const state = getState();
            return Promise.all(
                state.taskDefinitions.taskDefinitions.map((td: TaskDefinition) =>
                    api.getTDMeta(td.id),
                ),
            )
                .then(responses =>
                    responses.reduce(
                        (items, response) => items.concat(response.items),
                        [] as ApiGW.TaskDefinitionMetaDTO[],
                    ),
                )
                .then(tdItems => {
                    const taskDefinitions = state.taskDefinitions.taskDefinitions.map(
                        (td: TaskDefinition) => {
                            const taskDefinition = { ...td };
                            const tdItem = tdItems.find(
                                item => item.taskDefinitionId === taskDefinition.id,
                            );
                            if (tdItem) taskDefinition.managed = tdItem.managed;

                            return taskDefinition;
                        },
                    );
                    return dispatch({
                        taskDefinitions,
                        type: FETCH_META,
                    });
                })
                .catch(err => err);
        };
    },

    fetchTDSCount: (): ThunkActionPromise => {
        return (dispatch, getState, { api }) =>
            Promise.all(
                getState().taskDefinitions.taskDefinitions.map((td: TaskDefinition) =>
                    api.getTDMeta(td.id, true).then(response => {
                        if (response.items && response.items.length) {
                            const item = response.items.find(t => t.taskDefinitionId === td.id);
                            if (!item) throw new Error(`Task Definintion ${td.id} not found`);
                            const taskDefinitions = getState().taskDefinitions.taskDefinitions.map(
                                (taskDefinition: TaskDefinition) => {
                                    if (taskDefinition.id === item.taskDefinitionId) {
                                        return { ...taskDefinition, count: item.count };
                                    }
                                    return taskDefinition;
                                },
                            );
                            return dispatch({
                                taskDefinitions,
                                totalTasks: taskDefinitions.reduce(
                                    (acc, task) => acc + (task.count ?? 0),
                                    0,
                                ),
                                type: FETCH_META_COUNT,
                            });
                        }
                    }),
                ),
            ).catch(err => err);
    },

    getCreateTaskPermits: (): ThunkActionPromise => {
        return async (dispatch, getState, { api, toastsCreator }) => {
            try {
                dispatch({ type: TASK_PERMITS_LOADING, isLoading: true });
                const taskDefinitionsIds = getTaskDefinitionsIds(getState());

                const result = await Promise.all(
                    taskDefinitionsIds.map(id =>
                        api
                            .getTaskDefinitionPermit(id, CREATE_TASK_PERMIT)
                            .then(res => (res.items.length > 0 ? id : null)),
                    ),
                );
                const tdIdsWithCreateTask = result.filter(value => value !== null) as number[];

                dispatch({ type: GET_CREATE_TASK_PERMIT, tdIdsWithCreateTask });
                dispatch({ type: TASK_PERMITS_LOADING, isLoading: false });
            } catch (error: any) {
                dispatch(toastsCreator.createToast(error.message, 'error'));
                dispatch({ type: TASK_PERMITS_LOADING, isLoading: false });
            }
        };
    },

    getCreateTaskPermit: (taskDefinitionId: number): ThunkActionPromise => {
        return async (dispatch, _, { api, toastsCreator }) => {
            try {
                const result = await api
                    .getTaskDefinitionPermit(taskDefinitionId, CREATE_TASK_PERMIT)
                    .then(res => (res.items.length > 0 ? [taskDefinitionId] : []));

                dispatch({ type: GET_CREATE_TASK_PERMIT, tdIdsWithCreateTask: result });
            } catch (error: any) {
                dispatch(toastsCreator.createToast(error.message, 'error'));
            }
        };
    },

    getCreateTaskPermitOrPermits: (activeItemId: number): ThunkActionPromise => {
        return async (dispatch, getState, { taskDefinitionsCreator: tdsCreator }) => {
            if (activeItemId !== ALL_TASKS_ID) {
                await dispatch(tdsCreator.getCreateTaskPermit(activeItemId));
            }
        };
    },
};
