import moment, { MomentFormatSpecification } from 'moment';
import { RecursivePartial, Http } from 'worldapp-fe-utils';
import { ApiGW } from '../types/DTO/api-gw';
import { CreateTaskParams, DataModelRowsDTO } from '../redux/taskDefinitions/taskDefinitions.types';
import {
    FetchDashboardsParams,
    FetchResponsesParams,
    FetchTasksParams,
} from '../redux/content/content.types';
import { ApiDashboards } from '../types/DTO/dashboards';
import { PortalSettings } from '../types/custom';
import { ALL_TASKS_ID } from '../constants/navigation.constants';
import { Language } from '../localization/localizationDefaultData';
import { LogoutInfo } from '../redux/login/login.types';
import { appendParamsToRelativeURL } from '../utils/window.location.utils';
import { httpOptions } from '../utils/helpers.utils';

export const DEFAULT_LIMIT = 100000;
export const DEFAULT_OFFSET = 0;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const apiClientCreator = (http: Http) => ({
    login(username: string, password: string, portalId: number) {
        return http.post<ApiGW.AuthTokenDTO>('/gw/v3/auth/login', {
            username,
            password,
            portalId,
        });
    },

    logout(impersonateMode: boolean) {
        return http.post<LogoutInfo>(
            `/gw/v3/auth/logout${impersonateMode ? '?mode=IMPERSONATE' : ''}`,
        );
    },

    resetPassword(portalId: number, username: string) {
        return http.post<void>('/gw/v3/auth/passwords/reminder', {
            portalId,
            username,
        });
    },

    getAssigneeValues(taskDefinitionId: number, limit: number, offset: number) {
        const params: RecursivePartial<ApiGW.TaskAssigneeFilterValuesRequestDTOV3> = {
            limit,
            offset,
        };
        if (taskDefinitionId !== ALL_TASKS_ID) {
            params.taskDefinitions = [taskDefinitionId];
        }
        return http.post<
            ApiGW.TaskAssigneeFilterValuesDTOV3,
            RecursivePartial<ApiGW.TaskAssigneeFilterValuesRequestDTOV3>
        >('/gw/v3/tasks/filter/assignee', params);
    },

    getUser() {
        return http.get<ApiGW.UserDTO>('/gw/v1/users/me');
    },

    getPortalSettings() {
        return http.get<PortalSettings>(
            '/gw/v2/users/me/portal/settings?excludeTabsAvailabilityCheckFor=FORMS,TASKS',
        );
    },

    getTaskDefinitions(limit: number, offset: number) {
        return http.get<ApiGW.TaskDefinitionsDTO>(
            `/gw/v3/task-definitions?includeOptions=contactManager&limit=${limit}&offset=${offset}`,
        );
    },

    getTDMeta(taskDefinitionId: any, withCounters = false) {
        return http.post<ApiGW.TaskDefinitionsMetaDTO>('/gw/v3/task-definitions/meta', {
            withCounters,
            taskDefinitionIds: [taskDefinitionId],
        });
    },

    getForms() {
        return http.get<ApiGW.FormsDTOV3>(`/gw/v3/forms?limit=${DEFAULT_LIMIT}`);
    },

    getFormsTitles() {
        return http.get<ApiGW.FormsDTOV3>(
            `/gw/v3/forms?fields=items,id,title,totalCount&limit=${DEFAULT_LIMIT}`,
        );
    },

    getForm(formId: number) {
        return http.get<ApiGW.FormDTOV3>(`/gw/v3/forms/${formId}?include=settings`);
    },

    getResponseUrl(id: number) {
        return http.get<ApiGW.ResponseUrlDTO>(`/gw/v3/responses/${id}/url`);
    },

    copyResponse(id: number) {
        return http.post<ApiGW.ResponseDTOV3>(`/gw/v3/responses?copyOf=${id}`);
    },

    getBrandInfo(portalId: number | null) {
        const portalIdParam = portalId !== null ? `?portal=${portalId}` : '';
        return http.get<ApiGW.BrandInfoDTOV2>(`/gw/v2/brand-info${portalIdParam}`);
    },

    getContactFilterFields(taskDefinitionId: number) {
        return http.get<ApiGW.ContactFiltersDTO>(
            `/gw/v3/task-definitions/${taskDefinitionId}/contact-filters`,
        );
    },

    getTaskPropertyValues(taskPropertyId: number, limit: number, offset: number, query?: string) {
        const queryUrl = query ? `&query=${query}` : '';
        return http.get<ApiGW.TaskPropertyExistingValuesDTO>(
            `/gw/v3/task-properties/${taskPropertyId}/values?limit=${limit}&offset=${offset}${queryUrl}`,
        );
    },

    updatePassword(password: string) {
        return http.post<void>('/gw/v3/auth/passwords/updater', { password });
    },

    fetchResponses({
        filter: { completedResponses, formId, inProgressResponses, recentResponses },
        limit,
        offset,
    }: FetchResponsesParams) {
        const path = `/gw/v3/forms/${formId}/responses?limit=${limit}&offset=${offset}`;
        const params: Record<string, string> = {};
        if (inProgressResponses && !completedResponses) {
            params.responseStatuses = 'IN_PROGRESS,AUTO_CREATED';
        }
        if (!inProgressResponses && completedResponses) {
            params.responseStatuses = 'COMPLETED';
        }

        if (recentResponses) {
            const date = moment().subtract(2, 'days').format();
            params.updatedFrom = date;
        }
        return http.get<ApiGW.ResponsesDTOV3>(appendParamsToRelativeURL(path, params));
    },

    fetchContactFilterValues(
        filterId: number,
        query: string | null,
        taskId: number,
        filters: ApiGW.DataModelValueDTO[],
    ) {
        const postParams = {
            filters,
            query,
            limit: 1000,
            offset: DEFAULT_OFFSET,
            taskIds: [taskId],
        };

        return http.post<ApiGW.ContactFilterRowsDTO>(
            `/gw/v3/contact-filters/${filterId}/values`,
            postParams,
        );
    },

    fetchTasks(fetchParams: FetchTasksParams) {
        return http.post<ApiGW.TasksDTOV3, RecursivePartial<ApiGW.TasksSearchDTOV2>>(
            '/gw/v3/tasks/list',
            fetchParams.params,
        );
    },

    fetchDashboards({ limit, offset, searchQuery }: FetchDashboardsParams) {
        let request = `/dashboards/api/v9/users/me/reports?limit=${limit}&offset=${offset}`;
        if (searchQuery) request += `&searchQuery=${searchQuery}`;
        return http.get<ApiDashboards.DashboardsDTO>(request);
    },

    fetchCMViewColumns(viewId: number) {
        return http.get<ApiGW.DataModelColumnsDTO>(
            `/gw/v3/contact-manager/views/${viewId}/columns`,
        );
    },

    fetchCMViewValues(
        taskDefinitionId: number,
        taskIds: number[],
        query: string,
        filters: ApiGW.DataModelValueDTO[],
        limit: number,
        offset: number,
    ) {
        const postParams: ApiGW.ContactFilterCriteriaDTO = {
            query,
            filters,
            limit,
            offset,
            taskIds,
        };
        return http.post<DataModelRowsDTO>(
            `/gw/v3/task-definitions/${taskDefinitionId}/contacts?fields=-total`,
            postParams,
        );
    },

    getTask(taskId: number) {
        return http.get<ApiGW.TaskDTOV3>(`/gw/v3/tasks/${taskId}`);
    },

    getTaskHistory(taskId: number, limit: number = DEFAULT_LIMIT, offset: number = DEFAULT_OFFSET) {
        return http.get<ApiGW.TaskHistoriesDTOV3>(
            `/gw/v3/tasks/${taskId}/history?limit=${limit}&offset=${offset}`,
        );
    },

    assignTask(taskId: number, contactId: number | null) {
        return http.patch<void>(`/gw/v3/tasks/${taskId}`, { assigneeId: contactId });
    },

    setStatus(taskId: number, statusId: number) {
        return http.patch<void>(`/gw/v3/tasks/${taskId}`, { statusId });
    },

    unassignTask(taskId: number) {
        return http.patch<void>(`/gw/v3/tasks/${taskId}`, { assigneeId: null });
    },

    changeDueDate(taskId: number, dueDate: MomentFormatSpecification | null) {
        return http.patch<void>(`/gw/v3/tasks/${taskId}`, { dueDate });
    },

    deleteResponse(responseId: number) {
        return http.delete<void>(`/gw/v3/responses/${responseId}`);
    },

    getRespondentId(offlineId: string) {
        return http.get<ApiGW.ResponseIdDTO>(`/gw/v3/responses?offlineResponseId=${offlineId}`);
    },

    getLoginOptions() {
        return http.get<ApiGW.AuthSettingsDTO>('/gw/v3/auth/settings');
    },

    updateUser(language: Language) {
        return http.patch<void>(`/gw/v1/users/me`, { language });
    },
    checkFeatures(features: Array<string>) {
        return http.get<ApiGW.FeaturesDTOV3>(`/gw/v3/features?names=${features}`);
    },
    getTaskDefinitionPermit(taskDefinitionId: number, permit: string) {
        return http.get<ApiGW.TaskPermitsDTOV3>(
            `/gw/v3/task-definitions/${taskDefinitionId}/permits?name=${permit}`,
        );
    },

    createTask(taskDefinitionId: number, params: CreateTaskParams) {
        return http.post<ApiGW.TaskDTOV3>(
            `/gw/v3/task-definitions/${taskDefinitionId}/tasks`,
            params,
        );
    },

    getTaskDefinition(taskDefinitionId: number) {
        return http.get<ApiGW.TaskDefinitionDTO>(`/gw/v3/task-definitions/${taskDefinitionId}`);
    },

    exportTasks(exportParams: FetchTasksParams) {
        return http.post('/gw/v3/tasks/list', exportParams.params, {
            requestHeaders: {
                accept: 'application/vnd.ms-excel',
            },
            withResponseHeaders: true,
        });
    },

    getExportTasksStatus(location: string) {
        return http.get(`/gw${location}`);
    },

    downloadTasksExportedFile(location: string) {
        return http.post(`/gw${location}`, undefined, {
            requestHeaders: {
                accept: 'application/vnd.ms-excel',
            },
            withResponseHeaders: true,
            fileDownload: true,
        });
    },

    updateTasksBatch(tasksMultipleUpdate: ApiGW.TasksBatchUpdateDTOV3) {
        return http.patch('/gw/v3/tasks', tasksMultipleUpdate, { withResponseHeaders: true });
    },
});

export type ApiClient = ReturnType<typeof apiClientCreator>;

export const apiClient = apiClientCreator(new Http(httpOptions));
