import { USER_FETCHED, UserAction, UserState, CHANGE_USER_LANGUAGE } from './user.types';
// eslint-disable-next-line import/no-cycle
import { ThunkActionPromise } from '../index';
import { Language } from '../../localization/localizationDefaultData';
import { removeAuthenticated, setAuthenticated } from '../../utils/storage.utils';
import {
    STATUS_BAD_REQUEST,
    STATUS_UNAUTHORIZED_ERROR,
} from '../../constants/statusCodes.constants';
import { setAnalitycsUserId } from '../../analytics';

export const initialState: UserState = {
    contactId: 0,
    email: '',
    id: 0,
    lang: '',
    login: '',
    exists: false,
};

export default function reducer(state = initialState, action: UserAction): UserState {
    switch (action.type) {
        case USER_FETCHED:
            return {
                ...state,
                ...action.user,
                exists: true,
            };
        case CHANGE_USER_LANGUAGE:
            return {
                ...state,
                lang: action.lang,
            };
        default:
            return state;
    }
}

export const userCreator = {
    fetchUser: (): ThunkActionPromise => {
        return async (dispatch, getState, { api, userSelector }) => {
            if (userSelector.isUserExists(getState())) {
                return;
            }
            try {
                const fetchedUser = await api.getUser();
                setAnalitycsUserId(fetchedUser.id);
                setAuthenticated();
                dispatch({
                    user: fetchedUser,
                    type: USER_FETCHED,
                });
            } catch (e: any) {
                if (e?.status === STATUS_UNAUTHORIZED_ERROR || e?.status === STATUS_BAD_REQUEST) {
                    removeAuthenticated();
                } else {
                    return Promise.reject(e);
                }
            }
        };
    },

    changeUserLanguage:
        (language: Language): ThunkActionPromise =>
        async (dispatch, _, { api, portalSettingsCreator }) => {
            await api.updateUser(language);
            dispatch({
                type: CHANGE_USER_LANGUAGE,
                lang: language,
            });
            return dispatch(portalSettingsCreator.fetchSettings());
        },
};
