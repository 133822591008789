import cuid from 'cuid';
import { logApiPerformance } from '../analytics';

const testUrl = document.body.getAttribute('data-testurl');

export const httpOptions = { origin: testUrl, log: logApiPerformance };

export const generateUniqueKey = cuid;

export const getIdFromString = (string: string): string => string.replace(/\s/g, '_');
